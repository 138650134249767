














































import {
  TableColumn,
  TableComponent,
} from '@dnb-open-banking/vue-table-component';
import { PostDto } from '@portals/shared/admin/PostDto';
import { format } from 'date-fns';
import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';
import Loader from '@/components/Loader.vue';
import { listPosts } from '@/service/postService';

type Data = {
  posts: PostDto[];
  loading: boolean;
  error: Error | null;
};

export default Vue.extend({
  name: 'news',
  components: {
    Loader,
    TableComponent,
    TableColumn,
    DnbIcon,
  },
  data(): Data {
    return {
      posts: [],
      loading: false,
      error: null,
    };
  },
  async mounted() {
    await this.listPosts();
  },
  methods: {
    dateFormatter(date: Date) {
      if (!date) return 'N/A';
      return format(date, 'DD.MM.YYYY');
    },
    async listPosts() {
      this.loading = true;
      try {
        this.posts = await listPosts();
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    onRowClick(row: { data: PostDto }) {
      this.$router.push(`/news/post/${row.data.id}`);
    },
  },
});
